<template>
  <form id="contact_form" novalidate="novalidate">
    <v-card-text>
      <h4>
        <i class="fas fa-user-friends"></i>
        Ajouter un ami
      </h4>

      <v-text-field
        type="text"
        id="name"
        name="name"
        v-model="name"
        label="Nom du contact"
        :error-messages="nameErrors"
        @input="$v.name.$touch()"
        @blur="$v.name.$touch()"
      ></v-text-field>

      <v-text-field
        type="text"
        id="phone"
        name="phone"
        v-model="phone"
        label="Télèphone fixe"
        placeholder="+33123456789"
        :error-messages="phoneErrors"
        @input="$v.phone.$touch()"
        @blur="$v.phone.$touch()"
      ></v-text-field>

      <v-text-field
        type="text"
        id="mobile"
        name="mobile"
        label="Télèphone portable"
        placeholder="+33610234564"
        v-model="mobile"
        :error-messages="mobileErrors"
        @input="$v.mobile.$touch()"
        @blur="$v.mobile.$touch()"
      ></v-text-field>

      <v-text-field
        type="text"
        id="email"
        name="email"
        v-model="email"
        label="Email"
        placeholder="bob@free.fr"
        :error-messages="emailErrors"
        @input="$v.email.$touch()"
        @blur="$v.email.$touch()"
      ></v-text-field>

      <div class="justify-center">
        <v-btn
          type="button"
          class="btn-custom"
          variant="outline-primary"
          :loading="isLoading"
          :disabled="isLoading"
          @click="submit"
        >
          Ajouter
          <template v-slot:isLoading>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
      </div>
    </v-card-text>
  </form>
</template>
<style>
.v-text-field .v-label--active {
  transform: translateY(-24px) scale(0.75);
}

.v-text-field__slot,
.v-text-field__slot input {
  border: none;
}
</style>
<script>
import API from "@aws-amplify/api";
import * as manageFriend from "@/graphql/mutations";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required, email, helpers } from "vuelidate/lib/validators";
import * as queries from "@/graphql/queries";

const alpha = helpers.regex("alpha", /^[a-zA-ZÀ-ú \- s]+$/i);
const mobileNumber = helpers.regex("mobileNumber", /^(\+33|0)(6|7|9)\d{8}$/i);
const fixNumber = helpers.regex("fixNumber", /^(\+33|0)(1|2|3|4|5)\d{8}$/i);

export default {
  mixins: [validationMixin],
  validations: {
    name: { required, alpha },
    email: { required, email },
    mobile: { required, mobileNumber },
    phone: { fixNumber }
  },
  template: "addContact",
  components: {},
  data() {
    return {
      subscription: this.$store.state.auth.user.attributes[
        "custom:subscription"
      ],
      contactForm: null,
      descriptionLimit: 60,
      isLoading: false,
      selectedApp: [],
      name: "",
      phone: "",
      mobile: "",
      email: "",
      entries: []
    };
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Le nom est obligatoire");
      !this.$v.name.alpha &&
        errors.push("Le nom ne doit contenir que des lettres");

      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("l'adresse mail n'est pas valide");
      !this.$v.email.required && errors.push("l'adresse mail est obligatoire");
      return errors;
    },
    mobileErrors() {
      const errors = [];
      if (!this.$v.mobile.$dirty) return errors;
      !this.$v.mobile.required &&
        errors.push("Le numéro de portable est obligatoire");
      !this.$v.mobile.mobileNumber &&
        errors.push("Le numéros doit être sous la forme +33623456789");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      !this.$v.phone.fixNumber &&
        errors.push("Le numéros doit être sous la forme +33123456789");
      return errors;
    }
  },
  methods: {
    async submit() {
      const contact = {
        name: this.name,
        email: this.email
      };
      if (this.phone !== "") {
        if (this.phone.indexOf("+33") === -1) {
          this.phone = "+33" + this.phone.substring(1);
        } else {
          this.phone = this.phone;
        }
        contact.phone = this.phone;
      }
      if (this.mobile !== "") {
        if (this.mobile.indexOf("+33") === -1) {
          this.mobile = "+33" + this.mobile.substring(1);
        } else {
          this.mobile = this.mobile;
        }
        contact.mobile = this.mobile;
      }
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isLoading = true;

        const friendList = await API.graphql({
          query: queries.listCONTACTS
        });
        if (
          (friendList.data.listCONTACTS.items.length >= 1 &&
            (this.subscription === "FREE" ||
              this.subscription === undefined)) ||
          (friendList.data.listCONTACTS.items.length >= 5 &&
            (this.subscription === "PLAN1" || this.subscription === "PLAN2"))
        ) {
          Swal.fire({
            title: "Erreur",
            text: "Vous avez dépasser votre quota d'amis",
            icon: "error",
            heightAuto: false
          });
          return this.$emit("inserted", false);
        }
        API.graphql({
          query: manageFriend.createCONTACT,
          variables: {
            input: contact
          }
        })
          .then(() => {
            this.isLoading = false;
            this.showDialog = false;
            this.name = "";
            this.email = "";
            this.mobile = "";
            this.phone = "";
            this.$v.$reset();
            this.$emit("inserted", true);
          })
          .catch(err => {
            this.isLoading = false;
            Swal.fire({
              title: "Please, retry later",
              text: err.errors[0].message,
              icon: "error",
              heightAuto: false
            });
          });
      }
    },
    clear() {
      this.$v.$reset();
      this.showDialog = false;
      this.name = "";
      this.email = "";
      this.mobile = "";
      this.phone = "";
    }
  }
};
</script>
