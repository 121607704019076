/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCONTACT = /* GraphQL */ `
  mutation CreateCONTACT(
    $input: CreateCONTACTInput!
    $condition: ModelCONTACTConditionInput
  ) {
    createCONTACT(input: $input, condition: $condition) {
      id
      email
      phone
      mobile
      ip
      created
      unsubscribe
      unsubscribeDate
      unsubscribeip
      name
      notifications {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCONTACT = /* GraphQL */ `
  mutation UpdateCONTACT(
    $input: UpdateCONTACTInput!
    $condition: ModelCONTACTConditionInput
  ) {
    updateCONTACT(input: $input, condition: $condition) {
      id
      email
      phone
      mobile
      ip
      created
      unsubscribe
      unsubscribeDate
      unsubscribeip
      name
      notifications {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCONTACT = /* GraphQL */ `
  mutation DeleteCONTACT(
    $input: DeleteCONTACTInput!
    $condition: ModelCONTACTConditionInput
  ) {
    deleteCONTACT(input: $input, condition: $condition) {
      id
      email
      phone
      mobile
      ip
      created
      unsubscribe
      unsubscribeDate
      unsubscribeip
      name
      notifications {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createNotificationContact = /* GraphQL */ `
  mutation CreateNotificationContact(
    $input: CreateNotificationContactInput!
    $condition: ModelNotificationContactConditionInput
  ) {
    createNotificationContact(input: $input, condition: $condition) {
      id
      notificationId
      notificationCreated
      contactId
      status
      notification {
        id
        created
        updated
        createdAt
        updatedAt
        owner
      }
      contact {
        id
        email
        phone
        mobile
        ip
        created
        unsubscribe
        unsubscribeDate
        unsubscribeip
        name
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateNotificationContact = /* GraphQL */ `
  mutation UpdateNotificationContact(
    $input: UpdateNotificationContactInput!
    $condition: ModelNotificationContactConditionInput
  ) {
    updateNotificationContact(input: $input, condition: $condition) {
      id
      notificationId
      notificationCreated
      contactId
      status
      notification {
        id
        created
        updated
        createdAt
        updatedAt
        owner
      }
      contact {
        id
        email
        phone
        mobile
        ip
        created
        unsubscribe
        unsubscribeDate
        unsubscribeip
        name
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteNotificationContact = /* GraphQL */ `
  mutation DeleteNotificationContact(
    $input: DeleteNotificationContactInput!
    $condition: ModelNotificationContactConditionInput
  ) {
    deleteNotificationContact(input: $input, condition: $condition) {
      id
      notificationId
      notificationCreated
      contactId
      status
      notification {
        id
        created
        updated
        createdAt
        updatedAt
        owner
      }
      contact {
        id
        email
        phone
        mobile
        ip
        created
        unsubscribe
        unsubscribeDate
        unsubscribeip
        name
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createNOTIFICATION = /* GraphQL */ `
  mutation CreateNOTIFICATION(
    $input: CreateNOTIFICATIONInput!
    $condition: ModelNOTIFICATIONConditionInput
  ) {
    createNOTIFICATION(input: $input, condition: $condition) {
      id
      created
      updated
      contacts {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateNOTIFICATION = /* GraphQL */ `
  mutation UpdateNOTIFICATION(
    $input: UpdateNOTIFICATIONInput!
    $condition: ModelNOTIFICATIONConditionInput
  ) {
    updateNOTIFICATION(input: $input, condition: $condition) {
      id
      created
      updated
      contacts {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteNOTIFICATION = /* GraphQL */ `
  mutation DeleteNOTIFICATION(
    $input: DeleteNOTIFICATIONInput!
    $condition: ModelNOTIFICATIONConditionInput
  ) {
    deleteNOTIFICATION(input: $input, condition: $condition) {
      id
      created
      updated
      contacts {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
