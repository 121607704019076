/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCONTACT = /* GraphQL */ `
  query GetCONTACT($id: ID!) {
    getCONTACT(id: $id) {
      id
      email
      phone
      mobile
      ip
      created
      unsubscribe
      unsubscribeDate
      unsubscribeip
      name
      notifications {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCONTACTS = /* GraphQL */ `
  query ListCONTACTS(
    $filter: ModelCONTACTFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCONTACTS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        phone
        mobile
        ip
        created
        unsubscribe
        unsubscribeDate
        unsubscribeip
        name
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getNotificationContact = /* GraphQL */ `
  query GetNotificationContact($id: ID!) {
    getNotificationContact(id: $id) {
      id
      notificationId
      notificationCreated
      contactId
      status
      notification {
        id
        created
        updated
        createdAt
        updatedAt
        owner
      }
      contact {
        id
        email
        phone
        mobile
        ip
        created
        unsubscribe
        unsubscribeDate
        unsubscribeip
        name
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listNotificationContacts = /* GraphQL */ `
  query ListNotificationContacts(
    $filter: ModelNotificationContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationContacts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        notificationId
        notificationCreated
        contactId
        status
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getNOTIFICATION = /* GraphQL */ `
  query GetNOTIFICATION($id: ID!, $created: AWSDateTime!) {
    getNOTIFICATION(id: $id, created: $created) {
      id
      created
      updated
      contacts {
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listNOTIFICATIONS = /* GraphQL */ `
  query ListNOTIFICATIONS(
    $id: ID
    $created: ModelStringKeyConditionInput
    $filter: ModelNOTIFICATIONFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNOTIFICATIONS(
      id: $id
      created: $created
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        created
        updated
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
